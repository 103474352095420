.App {
    text-align: center;
}

.App-logo {
    height: 56px;
    pointer-events: none;
    border-radius: 100%;
}

h3 {
    margin: 0;
}

p {
    margin-top: 0;
    font-size: 20px;
}

.buttons {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.button {
    display: flex;
    border: 1px solid #e1e4e8;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background: #fff;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    box-shadow: 1px 2px 0 rgb(0 0 0 / 5%);
    -webkit-transition-property: background-color;
    transition-property: background-color;
    /*color: #444d56;*/
    color: #86c232;
    font-weight: 500;
    /*font-size: .875rem;*/
    font-size: 1rem;
    text-decoration: none;
}

.button > svg {
    font-size: 20px;
}

.button:hover {
    border-color: #d1d5da;
    background-color: #fafbfc;
    /*color: #1b1f23;*/
}

.button:active {
    background-color: #f2f5f8;
}

.App-header {
    /*background: linear-gradient(120deg, #730098, #560080, #4b0096, #250080);*/
    background-image: url('https://uploads-ssl.webflow.com/62259a0fd25200e36c368b9f/62279d6b214710af53a337f3_img-bg-gradient.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 26px;
}

.App-logo-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.content {
    margin-left: 30px;
    margin-right: 30px;
    background: rgba(255, 255, 255, 0.6);
    /*padding: 15px 80px 80px 10px;*/
    border-radius: 8px;
    padding: 20px 15px;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    /*width: 450px;*/
    /*height: 400px;*/
    box-shadow: 1px 2px 0 rgb(0 0 0 / 5%);
    border: 1px solid #e1e1e1;
}

.content > * {
    margin: 0;
    width: fit-content;
}

.content > h3 {
    font-size: 24px;
    width: 100%;
    /*font-weight: 600;*/
    /*width: 400px;*/
}

.content > p {
    text-align: left;
    font-size: 16px;
    /*margin-left: 20px;*/
}

@media screen and (max-width: 295px) {
    .App-logo-div {
        flex-direction: column;
    }
    .App-logo {
        height: 64px;
    }
}
